const resourcesConfig = {
  rewardsGreetingText: 'Loading exciting {br} rewards for you',
  home: 'Home',
  rewardClub: 'Reward Club',
  getGuestMobileNumber: `To Join our Rewards club, {br} Please provide your {br} Mobile number`,
  enterNumberToLogin:
    'Please enter your Mobile number to {br} login into your Rewards Club account',
  rewardCondition: 'I agree to receive information, deals and offers from {outletName}',
  verifyMobileNumber: 'Verify Mobile number',
  enterOtpSentOn: 'Enter OTP sent on {phoneNumber}',
  invalidPhoneNumberMessage: 'Please enter a valid mobile no.',
  tncError: 'Please agree to Terms & Conditions to proceed.',
  emptyOtpMessage: 'Please enter otp',
  guestProfile: 'Guest Profile',
  provideDetails: 'Please provide your details',
  notProvided: 'Not Provided',
  nameFieldLabel: 'Name',
  emailFieldLabel: 'Email id',
  birthDateFieldLabel: 'Date of birth',
  anniversaryDateFieldLabel: 'Marriage Anniversary',
  birthdayReward:
    'Your birthday is special for us too. {br} Get an exciting reward for your birthday month',
  anniversaryReward:
    'Elevate your anniversary celebration with us! {br} Enjoy a special reward to make your marriage anniversary unforgettable',
  birthDayAndAnniversaryReward:
    'Your special days are special for us too. {br} Get a special reward for your Birthday & Anniversary',
  hello: 'Hello',
  favouriteLocation: 'Please tell us your Favourite Location',
  selectOutlet: 'Please select the outlet(s) where you visit more often',
  invalidNameMessage: 'Please enter a valid name',
  invalidEmailMessage: 'Please enter a valid email',
  invalidDate: 'Please enter a valid date',
  invalidDateOfBirthMessage: 'Please enter a valid date',
  invalidDateOfAnniversaryMessage: 'Please enter a valid date',
  perferredOutletError: 'Please select your favourite outlet.',
  emptyOutletList: 'No Outlet Found!',
  getStartedCTAText: 'GET STARTED',
  proceedCTAText: 'PROCEED',
  joinRewardClubCTAText: 'JOIN REWARDS CLUb',
  doneCTAText: 'DONE',
  saveCTAText: 'SAVE',
  updateCTAText: 'UPDATE',
  resendOtp: 'Resend OTP',
  locations: 'Locations',
  locationDetail: 'Location Detail',
  getDirection: 'GET DIRECTION',
  call: 'CALL',
  today: 'Today',
  closed: 'Closed',
  errorInfo: {
    OTP_INVALID: {
      title: 'OTP not verified',
      message: 'It seems like you’ve entered a wrong otp.',
    },
  },
  rewardsClubText: 'Rewards Club',
  rewardProgramDescription:
    'A rewards club that is all about you. Some pep talk about the rewards program that goes into multiple lines covering about 50 words.',
  getAppRewardsClubDescriptionText1: 'Earn points for every rupee spent',
  getAppRewardsClubDescriptionText2: 'Redeem points for exciting rewards',
  getAppDownloadRewardsClubAppText: 'Download app to join Rewards Club',
  getAppRewardClubBenefits: 'Benefits of Rewards Club',
  getAppRewardClubOtherBenefits: 'Other Benefits',
  rewardsText: 'Rewards',
  couponsText: 'Old Coupons',
  rewardsDetailsText: 'Reward Details',
  name: 'Name',
  emailId: 'Email id',
  mobileNo: 'Mobile no.',
  dob: 'Date of Birth',
  dateOfBirthAddedToast: 'Date of Birth Added!',
  dateOfAnniversaryAddedToast: 'Anniversary Added!',
  favouriteLocationText: 'Favourite Locations',
  notification: 'Notifications',
  appNotification: 'App notification',
  sms: 'SMS',
  whatsapp: 'Whatsapp',
  email: 'Email',
  rewardPointHistory: 'Reward Points History',
  history: 'History',
  rewardProgram: 'Rewards Program',
  settings: 'Settings',
  profile: 'Profile',
  shareThisApp: 'Share this app',
  followUsOn: 'FOLLOW US ON',
  logOut: 'LOG OUT',
  login: 'Log in',
  logoutText: 'Logout',
  confirmLogoutMessage: 'Are you sure you want to logout?',
  deleteAccount: 'DELETE ACCOUNT',
  pointEarned: 'Points Earned',
  pointAdjusted: 'Points Adjusted',
  appPermissions: 'App Permissions',
  communication: 'Communication',
  preferredChannelText: 'Preferred channels for receiving marketing messages',
  offersText: 'Offers',
  getCouponCTA: 'GET COUPON',
  noBrandDealDescription: 'Seems like there are no offers at the moment',
  noBrandRewardDescription: 'Seems like there are no rewards at the moment',
  howThisWorks: 'How this works',
  howItWorks: 'How it Works',
  getCouponOnSubscribe: 'You’ll get a Coupon code on collecting this reward',
  showCouponBeforeOrder: 'Visit the restaurant and show the coupon code before placing your order',
  redeemCouponAtBillGeneration:
    'The restaurant will redeem your coupon at the time of bill generation',
  offerDetailsText: 'Offer Details',
  applicableForNewCustomers: 'Applicable for first time customers',
  maximumDiscountText: 'Maximum discount ₹{maxValue}',
  alreadyHaveCoupon: 'You already have a coupon code',
  visitRestaurantToReedem: 'Visit restaurant to redeem',
  useCouponOnNextOrder: 'Use this coupon on your next order',
  instantDealText: 'Instant Deal',
  viewDetailsText: 'VIEW DETAILS',
  couponStreakText: 'Coupon Streak',
  couponApplicableOn: 'Coupon Applicable on',
  viewOnMap: 'VIEW ON MAP',
  redeemedCouponAndSavedText: 'You have redeemed this coupon and saved {symbol}{discountAmount}',
  redeemedCouponAndAvailedText: 'You have redeemed this coupon and availed Free {applicableItem}',
  locked: 'Locked',
  unlockCouponAfterText: 'Unlock this coupon code after {orderCount} Order',
  redeemed: 'Redeemed',
  expired: 'Expired',
  rewardExpiredOnText: 'Reward expired on {expiredDate}',
  willUnlockOnText: 'This coupon will unlock on {unlockDate}',
  couponCodeLocked: 'Coupon Code Locked',
  showCouponToAvail: 'Show this coupon code to avail offer',
  yourCouponCode: 'Your Coupon Code',
  goToRewardsPageCTA: 'GO TO REWARDS PAGE',
  couponAdded: 'Coupon added in Claimed Rewards',
  howToRedeem: ' How to Redeem Coupon',
  visitRestaurantShowCoupon:
    'Visit the restaurant and show the coupon code before placing your order',
  restaurantWillRedeem: 'The restaurant will redeem your coupon and provide applicable {offerType}',
  deleteYourAccount: 'Delete your Account',
  areYouSure: 'Are you sure?',
  allInformationWillBeLost: 'All information related to your account will be lost forever.',
  otpSent: 'OTP Sent',
  resendOtpCaps: 'RESEND OTP',
  points: 'POINTS',
  rewardPointsText: 'R E W A R D {br} P O I N T S',
  rewardPointsSpentDescription: '{rewardPoints} {currencyName} available',
  unlockRewardMilestoreDescription: 'Unlock reward on reaching {pointPrice} points',
  viewOldCoupons: 'VIEW OLD COUPONS',
  viewMoreText: 'VIEW MORE',
  myRewards: 'My Rewards',
  myRewardsDescription: 'Redeem your collected rewards on next order',
  noRewardsText: 'No rewards at the moment',
  redeemableRewardsText: 'All your redeemable rewards will be shown here',
  excitingOffers: 'Exciting Offers',
  excitingOffersDescription:
    "We have some exciting offers for your next visit. It's for limited time, grab your coupon before it ends.",
  pointsRedeemed: 'Points Redeemed',
  pointsUsed: 'Points Used',
  pointsEarnedForSpending: 'Amount spent {currencySymbol} {netAmount}',
  claimedReward: 'Collected Reward - {sourceName}',
  noDataToShow: 'No Data to show',
  viewRewards: 'VIEW REWARDS',
  claimRewards: 'COLLECT REWARDS',
  viewOffers: 'VIEW OFFERS',
  dealRedeemedPopupTitle: 'Coupon already Redeemed',
  dealRedeemedPopupDescription:
    'Hi {username},{br}  You’ve already redeemed the coupon on {redeemDate}  and got discount of {amount}. We’ve a coupon you might be interested in.',
  dealAlreadyRedeemedItemFreeDescription:
    'Hi {username},{br} You’ve already redeemed the coupon on {redeemDate} and availed free item. We’ve a coupon you might be interested in.',
  dealAlreadySubscribedDescription:
    'Hi {username},{br} You’ve already claimed this coupon on {subscribedDate}. Please check Claimed Rewards section to view coupon code',
  lastUpdatedText: 'Last updated',
  redeemOn: 'Redeemed on {redeemTime}',
  expiredOn: `Expired on {expireTime}`,
  validTill: 'Valid till {expireTime}',
  validFromTo: 'Valid from {redeemableTime} - {expireTime}',
  validFrom: 'Valid from {redeemableTime}',
  validOn: 'Valid on {time}',
  added: 'Added',
  deducted: 'Deducted',
  pointsText: 'Points',
  actionText: 'Action',
  activityDetails: 'Activity Details',
  dateAndTime: 'Date & Time',
  billNo: 'Bill no.',
  billAmount: 'Bill Amount',
  outlet: 'Outlet',
  claimedRewardText: 'Claimed {br} Reward',
  earnPointOneSpend: 'Earn point on every spend',
  earnPoints: 'Earn Points',
  unlockRewards: 'Unlock & Claim Rewards',
  unlockRewardsPromotion: 'Unlock Rewards',
  redeem: 'Redeem',
  redeemEarnedRewardsOnNextVisit:
    'Show the coupon code and Redeem your collected rewards on your next visit.',
  redeemEarnedRewardsOnNextVisitPromotion: 'Redeem your earned rewards on your next visit.',
  getBirthdayRewards: 'Get Birthday Rewards',
  getAnniversaryRewards: 'Get Anniversary Rewards',
  addBirthday: 'Add Birthday',
  addAnniversary: 'Add Anniversary',
  manyMoreOffers: 'Many more Offers',
  reasonToVisitUsAgain: 'We make sure that you always have a reason to visit us again.',
  birthdayRewardCardTitle: 'Get Exciting Reward for your Birthday Month',
  anniversaryRewardCardTitle: 'Get Exciting Reward for your Anniversary Month',
  birthdayProvidedInfo:
    'Thanks for Providing your Birthday. {br}To view your Birthday Rewards go to {br}More > Rewards Program',
  anniversaryProvidedInfo:
    'Thanks for Providing your Anniversary Date. {br}To view your Anniversary Rewards go to {br}More > Rewards Program',
  claimBirthdayRewardText: 'Birthday Reward',
  claimAnniversaryRewardText: 'Anniversary Reward',
  birthdayRewardsText: 'Birthday Rewards',
  welcomeRewardText: 'Welcome Reward',
  welcomeRewardDescription: 'Your reward is just a tap away',
  redeemRewardPointsText: 'Collect Reward with {currencyName}',
  pointsAvailable: '{rewardPoints} {currencyName} available',
  birthdayRewardsTitle: 'Choose from a variety of rewards for your birthday month',
  birthdayRewardDetails: 'Birthday Reward Details',
  claimCTAText: 'Claim it',
  birthdayRewardAvailableDate: 'Available from {startDate} - {endDate}',
  anniversaryRewardAvailableDate: 'Available from {startDate} - {endDate}',
  active: 'Active',
  noActiveRewardText: 'No Active Rewards at the moment',
  noInactiveRewardText: 'No Redeemed / Expired Rewards at the moment',
  redeemedOrExpired: 'Redeemed / Expired',
  greeterContent: 'Glad to have you back',
  notTakenRewards: `You've not taken any Rewards yet`,
  invalidOTPMessage: 'The OTP you have entered is incorrect',
  showThisCodeToRedeem: 'Redeem this coupon{br} on your next order',
  cretingSomethingExciting: 'We’re creating something exciting !!! {br} Please check back later',
  swipeToClaimReward: 'Swipe to Collect',
  joinRewardClubLink: 'Not a member yet? {joinRewardsClub}',
  joinRewardsClub: 'Join Reward Club',
  alreadyAMember: 'Already a Member? {login}',
  welcomeRewardsText: 'Welcome Rewards',
  welcomeRewardDetails: 'Welcome Reward Details',
  welcomeRewardsTitle: 'Choose from a variety of rewards',
  anniversaryRewardsText: 'Anniversary Rewards',
  anniversaryRewardDetails: 'Anniversary Reward Details',
  anniversaryRewardsTitle: 'Choose from a variety of rewards for your anniversary month',

  claimRewardCTAText: 'Collect Reward',
  greetingSubtitle: ' ',
  greetingTitle: ' ',
  joiningRewardText: 'Joining Reward',
  joiningReward:
    'We know how to welcome our guests. Get a special reward for joining Rewards Club.',
  accountAlreadyExists: 'Account exists. Please log in.',
  accountNotExists: "Account doesn't exists. Please signup",
  welcomeOnboard: 'Welcome Onboard,{br} {name}',
  viewOtherBenefits: 'View Other Benefits',
  collectPoint: 'Collect {earn} point for every {unit} {spend} spent.',
  collectPointWithCurrency: 'Collect {earn} {currencyName} for every {unit} {spend} spent.',
  collectPointInPer: 'Earn {earn}{unit} of bill value as {currencyName}',
  spendAndEarnPointOnSelectedItems:
    'You earn {earn} point for every Rs. {spend} spent on items labelled with “Earn Points”. Look for the below tag to identify eligible items.',
  earnPointOnSelectedItems: 'Earn {earn} {currencyName} every time you purchase {tag}',
  spendAndEarnPoint:
    'Every time you spend, you collect points. {unit} {spend} spent = {earn} point.',
  minRedeemPoints:
    'On reaching {minRedeemPrice} points, you get to choose from a variety of rewards. Collect reward of your choice.',
  minRedeemPointsPromotion:
    'On reaching {minRedeemPrice} points, you get to choose from a variety of rewards.',
  unlockRewardOn: 'Unlock rewards on reaching {minRedeemPrice} points.',
  useEarnPoints: 'Use earned points on your next order',
  useEarnPointsWithCurrency: 'Pay for your order with {currencyName}',
  userEarnPointInPer: 'Use these coins to pay upto {spend}{unit} of your bill',
  okGotIt: 'Ok, got it',
  knowMore: 'Know more',
  dealSubscribedPopupTitle: 'Coupon already Claimed',
  rewardApplicableOn: 'Coupon Applicable On',
  nearbyOutlets: 'Nearby Outlets',
  limitedOutletCouponTnC: 'Coupon applicable on limited outlets. Please see the below list',
  singleUseCouponTnC: 'This is a Single use coupon',
  offerNotClubbedCouponTnC: 'This offer cannot be clubbed with any other offer',
  redemptionCouponTnC: 'Redemption of coupon is at restaurant discretion',
  minimumOrderValueCouponTnC:
    'Minimum order amount of {currencySymbol}{minOrderValue}. It should be exclusive of taxes and other charges.',
  newlyLaunchedOutlets: 'Newly Launched Outlets',
  limitedOutletCouponTnC2: 'Coupon applicable on limited outlets.',
  nextOfferActivateCouponTnC:
    'Next offer will be activated after {couponApplicableAfter} of previous coupon redemption.',
  maximumDiscountCouponTnC: 'Maximum discount {currencySymbol}{maxValue} per order',
  getOffer: 'Get {offer}',
  limitedTimeOffersText: 'Limited Time Offers',
  appUpdateTitle: '{brandName} app needs an update',
  appUpdateDescription: 'To use this app, you need to update this app to the latest version.',
  appUpdatePrimaryCTA: 'UPDATE',
  recentlyVisitedOutlets: 'Recently Visited',
  orderTitle: 'Order',
  yourOrder: 'Your Order',
  coupons: 'Coupons',
  promoCode: 'Promo Code',
  coins: 'Coins',
  additionalDetails: 'Additional Details',
  proceedToPayCTAText: 'Proceed To Pay',
  selectDeliveryLocation: 'Select Delivery Location',
  cartHeaderTitle: 'Cart',
  itemTotalText: 'Item Total',
  discountText: 'Discount',
  additionalDiscount: 'Additional Discount',
  freeItemText: 'Free Item',
  taxesText: 'Taxes',
  toPayText: 'To Pay',
  amountText: '{currencySymbol}{amount}',
  discountAmountText: '- {currencySymbol}{amount}',
  offerItemText: 'Offer Item',
  offerItemsText: 'Offer Items',
  freeText: 'FREE',
  userAccessBlocked: 'User access blocked',
  gotToHomeCTA: 'GO TO HOME',
  userAccessBlockedDescription:
    'This reward cannot be collected. {br} Mobile number associated with this account has been blocked by administration.',
  mobileNumberBlocked: 'This mobile no. is blocked by administration.',
  offerCollectedMultipleTimes: 'This offer can be collected {perGuestMax} times.',
  offerAlreadyRedeemedMultipleTimes:
    'You’ve already redeemed this offer multiple times, please go to More > History to view details',
  addAmountToApplyCoupon: 'Add Rs {amount} more to apply this coupon',
  orderPlaced: 'Order Placed',
  orderConfirmed: 'Order Confirmed',
  orderPartiallyConfirmed: 'Order Partially Confirmed',
  orderReady: 'Order Ready',
  orderServed: 'Order Served',
  orderCancelled: 'Order Cancelled',
  orderFailed: 'Order Failed',
  receivedOrderConfirmShortly: 'We’ve received your order and will shortly confirm it',
  startedPreparingOrder:
    'We’ve started preparing your order. It will be ready by {preparationTime}',
  startedPreparingOrderWithoutTime: 'We’ve started preparing your order',
  someItemCancelled: 'Some items have been cancelled',
  orderServedEnjoyMeal: 'Your order has been served. Enjoy your meal!',
  orderUnavailable:
    'Sorry, your order is unavailable at this time. Deducted amount will be refunded within 7 working days.',
  transactionFailed:
    'Transaction failed: If any funds were deducted, they will be refunded within 7 working days.',
  viewCancelledItems: 'View Cancelled items',
  orderId: 'Order ID',
  orderNo: 'Order no.',
  dateTime: 'Date & Time',
  discount: 'Discount',
  total: 'Total',
  orderDetails: 'Order Details',
  paymentDetails: 'Payment Details',
  refundDetails: 'Refund Details',
  applyRewardCoupon: 'Apply Coupon / {currencyName}',
  applyCouponText: 'Apply Coupon',
  emptyMenuListPlaceholder:
    'No results match with the search criteria. Please clear the search to view the menu.',
  emptyApplicableMenuListPlaceholder: 'No items are available at the moment.',
  tableNumber: 'Table {number}',
  scanQrCode: 'Scan {br} QR code',
  outletReadMode: 'We are not accepting online orders at the moment',
  outletDeactiveMode: 'We are not accepting online orders at the moment',
  outletClosedMode: 'We are closed for the day',
  outletNotOpenMode: 'We are not yet open for the day',
  notAcceptionOrdersText: 'We are not accepting {serviceType} orders at the moment',
  accountDeletionStarted: 'Account Deletion Started',
  recoverAccountText:
    'If you wish to recover this account, please contact us immediately at support@fudr.in',
  outOfRestaurantScopeErrorDescription:
    'Seems like this QR doesn’t belong to {brandId}.{br} Please scan a valid {brandId}’s QR code.',
  aboutUs: 'About Us',
  orders: 'Orders',
  menu: 'Menu',
  recommended: 'Recommended',
  cameraAccessError: 'Seems like camera access is blocked',
  enterManualCode: 'Please enter the restaurant code mentioned below QR code',
  scanQRCodeText: 'Scan QR code to order',
  undetectScanCode: 'Not able to Scan',
  enterCodeCTA: 'Enter Code',
  nonFudrQRCodeText: 'Please Scan a {brandId} QR code',
  enterCodeError: 'Incorrect restaurant code. Please check the code and retry.',
  invalidCodeError: 'Please enter valid restaurant code.',
  scanCodeText: 'Scan Code',
  viewMenuCTA: 'View Menu',
  pickupTimePopupTitle: 'Pickup Time',
  pickupTimePopupDescription:
    'Tell us about your estimated arrival time. This will help us to serve you better',
  selectCityTitle: 'Select City',
  viewCartCTA: 'View Cart',
  menuCategoriesTitle: 'Explore Menu',
  lastUpdatedTime: 'Last updated {time}',
  collectReward: 'Collect Reward',
  itemDetailsTitle: 'Item Details',
  pickupOrderInstruction: 'You’ll need to pickup this order from {br} {restaurantName}',
  couponApplied: 'Coupon Applied',
  itemsInCartPopupTitle: 'Items in Cart',
  itemsInCartChangeOutletPopupDescription: 'You’ll lose items in your cart if you change outlet',
  itemsInCartChangeModePopupDescription: 'You’ll lose items in your cart if you change mode',
  changeOutletCTA: 'Change Outlet',
  changeModeCTA: 'Change Mode',
  collectRewardsDescription:
    'You can collect rewards by using your {currencyName}.{br} Go to Rewards page and collect rewards',
  updateAppTitle: 'App Update Available',
  updateAppDescription: 'A new version of app is available. Update now for best experience.',
  updateAppCTA: 'UPDATE APP',
  selectOptional: 'Optional',
  selectOne: 'Select one',
  repeatCustomizationPopupTitle: 'Repeat last customization?',
  getDirections: 'get directions',
  orderCTA: 'order',
  outletTimings: 'Timings',
  multipleCustomizationsPopupTitle: 'Multiple Customizations',
  multipleCustomizationsPopupCTA: 'Go to cart',
  multipleCustomizationsPopupDescription:
    'This item has multiple customizations added. Proceed to cart to remove item?',
  multipleCustomizationsBogoPopupDescription:
    'This item has multiple customizations added. Remove this item from the below selected items list.',
  noOrdersToShow: 'No Orders to show',
  orderingComingSoon: 'Ordering Coming Soon',
  nonExistSubdomainFallbackTitle: 'Unable to reach this page',
  nonExistSubdomainFallbackDescription:
    'It seems some error has occured. Please try after sometime',
  nonExistSubdomainFallbackCTA: 'TRY AGAIN',
  nonVeg: 'Non Veg',
  pureVeg: 'Pure Veg',
  customizable: 'customizable',
  orderRunning: '{count} order running',
  orderAgain: 'Order Again',
  moreDetails: 'More Details',
  selectOutletManuallyCTA: 'Select Outlet Manually',
  locationPermissionDenialTitle: 'Not able to determine your device location.',
  locationPermissionDenialDescriptionAndroid:
    "For best experience, Please allow access to device's location. Go to settings > {brandName} app > Allow access to location",
  locationPermissionDenialDescriptionIOS:
    "For best experience, Please allow access to device's location. Go to settings > {brandName} app > Allow access to location",
  locationPermissionDenialDescriptionBrowser:
    "For best experience, Please allow access to device's location.",
  addCTA: 'ADD',
  viewCTA: 'VIEW',
  earnPointsPopupTitle: 'Reward Program',
  helpFaqTitle: 'Support',
  helpSupportTitle: 'Help & Support',
  supportDescription: `For any queries or issues,{br}please mail us at`,
  helpSupportMail: 'support@fudr.in',
  pointsRefreshedToast: '{currencyName} updated successfully',
  pointsRefreshDelayInMS: 1000,
  refundTxnInitiated: 'Refund of ₹{totalRefundAmount} initiated on {initiateDate}',
  refundTxnProcessed: 'Refund of ₹{totalRefundAmount} processed on {processedDate}',
  someItemCancelledPostPaidOrder: 'Some items in this order were cancelled',
  someItemCancelledPrePaidOrder:
    'Some items in this order were cancelled. Balance amount will be refunded in 3-5 business days',
  dineInMinDistanceInMeter: 20,
  selfPickupMinDistanceInKM: 10,
  notAtOutlet: 'Not at Outlet',
  tooFarFromOutet: 'Too Far From Outlet',
  outletOutOfRangeDescription:
    "Seems like you're too far from outlet. Please make sure you're ordering at the correct outlet",
  okUnderstandCTA: 'OK, I UNDERSTAND',
  loaderTimeoutInMilliSeconds: 5000,
  repeatLast: 'Repeat Last',
  addNew: 'Add New',
  howToRedeemText: 'How To Reedem',
  collectRewardOnlineInstruction: 'Collect reward, Order from app and apply coupon on cart page',
  collectRewardOfflineInstruction:
    'Collect reward, visit the restaurant and ask restaurant to apply the coupon at the time of placing order.\nRestaurant will require your registered mobile no. to generate OTP to redeem coupon.',
  orDivider: '--OR--',
  subscribeMembershipOnlineInstruction:
    'Subscribe membership, Order from app and apply coupon on cart page',
  subscribeMembershipOfflineInstruction:
    'Subscribe membership, visit the restaurant and ask restaurant to apply the coupon at the time of placing order.\nRestaurant will require your registered mobile no. to generate OTP to redeem coupon.',
  emptyVariations: 'Current selection is not available at the moment',
  streakItemSequenceTitle: '{sequence} order',
  rewardPointEarnedTextWithCurrency: `{rewardPoints} {currencyName} {br} Earned on this order`,
  rewardPointEarnedText: `{rewardPoints} {br} Earned on this order`,
  useRewardPoint: 'Use {currencyName}',
  rewardPointUsed:
    '{redeemablePoints} {currencyName} will be used {br} Available - {balancePoints} points',
  availablePointToUse:
    'Available - {balancePoints} points {br} {redeemablePoints} {currencyName} will be used',
  zeroPointAvailable: 'Available - {balancePoints} points',
  pointDiscountText: 'Discount ({currencyName})',
  savedOnOrder: ' You’ll save {currencySymbol}{amount} on this order',
  youWillSave: 'You’ll save {currencySymbol}{amount}',
  rewardPointsTitle: '{currencyName}',
  okGotItCTA: 'Ok, Got it',
  billAmountPaidWithPoints:
    'Redeem your earned points on your next order. {billAmount}% of the bill amount can be paid using {currencyName}',
  maxRedeemablePointsPer:
    'A maximum of {maxValue}% of the available {currencyName} can be used per order',
  maxRedeemablePoints: 'A limit of {maxValue} {currencyName} applies to each order',
  vipPassTitle: '{vipPass}',
  vipPassSubtitle: 'Choose from a variety of {vipPass}',
  vipMaxDiscount: 'Max. Discount ₹{maxValue} per order',
  vipPassDetailsText: '{vipPass} Details',
  swipeToBuy: 'SWIPE TO BUY',
  redeemPointOnNextOrder:
    'Redeem your earned points on your next order. {percentage}% of the bill can be paid through reward points. ',
  rewardPointToMoney: '{point} {currencyName} = {currencySymbol}{amount}',
  passDetails: 'Pass Details',
  noMembershipDescription: 'Seems like you’ve not taken any pass yet',
  vipPassActiveDays: 'Valid for {activeDays} days',
  membershipValidity: 'Valid till {membershipExpirationTime}',
  renew: 'RENEW',
  membershipPuschased: 'Purchased - {activationTime}',
  membershipConclulded: 'Concluded - {expireTime}',
  renewMembershipText: '{vipPass} is expired. Renew your pass and keep enjoying the benefits again',
  membershipCouponUsed: '{totalUsed} Coupon used',
  backToHomeCTA: 'Back To Home',
  stopAndBuy: 'Stop Current Pass and Buy this Pass',
  buyThisPass: 'Buy This Pass',
  stopAndBuyVIPDescription:
    'You’ll lose remaining benefits of your currently active {vipPass} if you buy this pass',
  vipPassBuyErrorMessage:
    'If you buy this pass, remaining benefits of your currently active {vipPass} will be stopped',
  couponRedeemedOn: 'Redeemed on {expireTime}',
  couponValidity: 'Valid till {expireTime}',
  noCouponToShow: 'No coupons to show',
  membershipCouponPerGuestMax: 'Pass is valid for {perGuestMax} orders upto {activeDays} days',
  recaptchaBranding:
    'This site is protected by reCAPTCHA and the Google {privacyPolicyLink} and {tncLink} apply',
  recaptchaPrivacyPolicy: 'Privacy Policy',
  recaptchaTnC: 'Terms & Conditions',
  savedByUsingRewardPoint: 'You saved  ₹{amount} by using {point} {currencyName} on this order',
  rewardPoints: 'Reward Points',
  loyaltyPoints: 'Loyalty Points',
  redeemPoints: 'Redeem Points',
  membershipAlreadySubscribedTitle: 'Pass already active',
  membershipAlreadySubscribedDescription:
    'Seems like the pass you are trying to subscribe is already active for your account',
  stayHereCTA: 'STAY HERE',
  passName: 'Pass Name',
  purchasedOn: 'Purchased on',
  validTillText: 'Valid till',
  amount: 'Amount',
  paymentMode: 'Payment Mode',
  paymentStatus: 'Payment Status',
  transactionOn: 'Transaction On',
  referenceId: 'Reference ID',
  refundMode: 'Refund Mode',
  refundStatus: 'Refund Status',
  remark: 'Remark',
  initiatedOn: 'Initiated on',
  processedOn: 'Processed on',
  paymentFailed: 'Payment Failed',
  passPaymentFailedDescription:
    'Payment for this pass has not been received. If any amount was deducted it will be refunded within 7 business days',
  updateRewardProgramTitle: 'We’ve updated our {br} Rewards program',
  seeWhatsNewCTA: 'SEE WHATS NEW',
  rewardPointRedeemRule:
    'Pay upto {maxEarn}{unit} of your bill with {currencyName}. {earn} {currencyName} = {currency}{spend}',
  updateRewardProgramTnC:
    'Your current Rewards Points will be converted into {currencyName}. {br} {br} All collected rewards will be available for redemption',
  unlimitedOrders: 'all',
  minCartValueForPointsRedemption:
    'Minimum Order of {currencySymbol}{minOrderValue} (before tax) required {br} to apply {currencyName} on this order',
  minCartValueForPointsRedemptionRewardDetails:
    '{currencyName} can be applied on orders above {currencySymbol}{minCartValueForPointsRedemption}',
  coinsCannotBeUsed: '{currencyName} cannot be used on this order',
  deliveryLocation: 'Delivery Location',
  selectLocation: 'Select Location',
  addressDetails: 'Address Details',
  deliveryLocationTitle: 'Delivery Location',
  addressLine: 'House / Flat / Floor no.',
  landmark: 'Landmark / How to reach (optional)',
  addressMaxLength: 200,
  saveAs: 'Save as',
  office: 'Office',
  other: 'Other',
  notdeliverableText: 'Sorry, we’re not delivering at this location',
  selectDeliveryAddress: 'Select Delivery Address',
  deleteYourAddress: 'Delete Address',
  dontDelete: "DON'T DELETE",
  delete: 'DELETE',
  areYouSureAddress: 'Are you sure, you want to delete this address',
  placeHolderTitle: "Eg. John's Place",
  deliveringAt: 'Delivering at',
  deliveredAt: 'Delivered at',
  orderOutforDelivery: 'Out for Delivery',
  orderDelivered: 'Order Delivered',
  orderoutDescription: '{rider} is out for delivery and will be there soon',
  orderoutEtaDescription: '{rider} is out for delivery and will be there by {time}',
  orderoutDescriptionDefault: 'Our rider is out for delivery and will be there soon',
  orderDeliveredDescription: 'Your order has been delivered Enjoy your meal!',
  orderReadyforDispatch: 'Your order is ready and will be dispatched shortly',
  orderReadyforPickup: 'Your order is ready{br}Please collect it from the counter',
  deliverTo: 'Deliver to',
  membershipExpiredTitle: 'Your {vipPassTitle} Expired',
  getNewPassCTA: 'Get a New Pass',
  membershipExpiredDescription: 'Get a new pass and keep enjoying the discount',
  discountSavedUsingMembership:
    'You have used this pass {totalUsed} times and saved {currencySymbol}{totalDiscount}',
  whereYouAt: 'Where you at?',
  chooseOutlet: "Choose the outlet where you're making the payment.",
  selectBrand: 'Select Brand',
  payingAtOutlet: 'Paying - {outletName}',
  totalPayable: 'Total Payable',
  willSaveAmount: 'You’re saving {currency}{amount}',
  billPaid: 'Bill Paid',
  billCanPaidWithPoints: '{billAmount}% of the bill can be paid with {currencyName}',
  everySpendEarnPoint: 'For every {unit}{spend} spent,  you earn {earn} {currencyName}',
  spotlightTitle: 'Spotlight',
  mapMarkerDeliveryText: 'Order will be delivered here',
  noSavedAddressText: 'No saved addresses found',
  redirectingToPG: 'Redirecting to {br} Payment Gateway',
  doNotCloseApp: 'Please do not close the application',
  processingPayment: 'Processing Payment',
  processingPaymentDescription:
    'We are processing your payment, {br} please do not close the application',
  currentLevelTagText: 'Current Level',
  tierEligibilityVisit:
    'Visit {minSpend} times within {duration} {durationUnit} to reach this level',
  tierEligibilitySpend:
    'Spend {currency}{minSpend} within {duration} {durationUnit} to reach this level',
  tierEligibilityOrder:
    'Order {minSpend} times within {duration} {durationUnit} to reach this level',
  tierEligibilityPoint:
    'Earn {minSpend} points within {duration} {durationUnit} to reach this level',
  tierProgramPromotionTNC:
    'Earn {currencyName} with every purchase and redeem them towards your bill payments. Each {currencyName} holds a value of {currency}1, allowing you to save as you spend.{br}{br}Ascend through tier levels to unlock additional perks and benefits',
  viewTierLevelsCTA: 'View Tier Levels',
  additionalBenefits: 'Additional Benefits',
  tierLevelsPopupTitle: 'Tier Levels',
  myBenefitsTabLabel: 'MY BENEFITS',
  levelTabLabel: 'LEVELS',
  faqsTabLabel: 'FAQS',
  enterAmountShownOnBill: 'Enter amount shown on the bill',
  coinsCantBeClubbedWithCoupon: '{currencyName} cannot be clubbed with coupons',
  nextText: 'NEXT',
  billDetails: 'Bill Details',
  totalBillAmount: 'Total Bill Amount',
  coinUsed: 'Coin used',
  totalSaving: 'Total Saving',
  selectOutletText: 'Select Outlet',
  noOutletForSelectedCity: 'No outlet for selected city',
  amountSaveOnBill: '{currency}{amount} saved on your bill',
  amountPaid: 'Amount Paid',
  transactionId: 'Transaction ID',
  cancelPayment: 'Cancel Payment',
  exitPayEarnConfirmationMsg: 'Are you sure you do not want to pay bill and earn {currencyName}',
  doNotPayCTA: 'DO NOT PAY',
  outletSize: 100,
  earnCoinInPercentage: 'Earn {earn}{unit} of bill value as {currencyName}',
  tryAgainCTA: 'Try Again',
  tooFarFromOutetDescription:
    'Seems like you are too far from outlet. Please make sure you’re paying at the correct outlet',
  nearbyOutletDistanceInMeter: '20',
  annualSpend: 'Annual spend {currency}{amount}',
  tierTitle: '{title} Level Benefits',
  loyaltyCardTierTitle: '{title} Level',
  applyOffersOnNextPage: 'Apply coins / coupon on next page',
  swipeToPayAmount: 'SWIPE TO PAY {currency}{amount}',
  termsAndConditions: 'Terms & Conditions',
  inTheTier: 'In the {currentTier} level,',
  coinsEarned: '{amount} {currencyName} Earned',
  transactionDetails: 'Transaction Details',
  cookingRequest: 'Cooking Request ',
  readOnlyMenuServiceTitle: 'Pickup',
  paymentFailedDescription:
    'We are unable to process your payment. If any money was deducted it will be refunded in 2-3 business days',
  backToCartCTA: 'BACK TO CART',
  paymentSuccess: 'Payment Successful',
  userLocationSearchPlaceholder: 'Search by locality or street name',
  allOutlets: 'All Outlets',
  doNotRemove: 'DO NOT REMOVE',
  remove: 'Remove',
  switchRewardWarningTitle: 'Applied {type} will be removed',
  switchRewardWarningDesc: 'If you change the tab, {type} applied on your bill will be removed.',
  coinsText: 'coins',
  couponText: 'coupon',
  paymentProcessingInSeconds: '180',
  rewardProgramDetails: 'Reward program details',
  bookTable: 'Book Table',
  tableOutlet: 'Choose the outlet where you want to book table',
  helpAndSupport: 'Support',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  privacyPolicyAndTermsOfServiceDescription:
    'By proceeding, I agree to {brand}’s {br}{privacyPolicy} and {termsOfService}',
  privacyPolicyAndTermsOfServiceDescriptionInternal:
    'By proceeding, I agree to {brand}’s {privacyPolicy} and {termsOfService}',
  invalidGroupSizeMessage: 'Please enter valid group size',
  exitTableBooking: 'Exit Table Booking?',
  dontWantToBookTable: 'Are you sure you do not want to book a table?',
  dontBookTableCTA: 'DONT BOOK TABLE',
  bookingForLargeParty: 'Booking for Large Party?',
  giveUsCallForLargeParties:
    'Please give us a call for booking for large parties. This will help us to provide you a better experience',
  bookedSlotWillbeCancelled: 'If you cancel your booking, your booked slot will be cancelled.',
  cancelBooking: 'CANCEL BOOKING',
  dontCancel: 'DON’T CANCEL',
  guests: 'Guests',
  whenAreYouVisiting: 'When are you visiting?',
  noSlotsAvailable: 'No slots available',
  customCaps: 'CUSTOM',
  callOutlet: 'Call Outlet',
  callOutletCaps: 'CALL OUTLET',
  forLargeParties: 'For Large Parties',
  tableBooked: 'Table Booked',
  bookingCancelled: 'Booking Cancelled',
  forGroupSize: 'For {groupSize}',
  payAndEarn: 'Pay & Earn',
  bookingDetails: 'Booking Details',
  bookings: 'Bookings',
  bookingCancelledCaps: 'BOOKING CANCELLED',
  seatsLeft: '{remains} Seats Left',
  bookTableConfig: {
    default: {
      group: { size: [1, 2, 3, 4, 5, 6, 7, 8], isCustomizable: true },
      date: {
        maxDaysAhead: 30,
        bookingTimeLimitInMin: 0,
        slots: [
          { start: '08:00', end: '10:00', type: 'BREAKFAST' },
          { start: '12:00', end: '14:00', type: 'LUNCH' },
          { start: '18:00', end: '24:00', type: 'DINNER' },
        ],
      },
      tnc: [
        'Reach the restaurant 15 mins before your booked slot',
        'Booking cannot be transferred to another individual',
        'Booking will be cancelled if not reached on time',
        'Right of admission reserved',
      ],
      lookbackBookingsTimeInHours: 6,
    },
  },
  addOnMinSelection: 'Required | Select any {minSelection} option',
  addOnMinSelectionMultiple: 'Required | Select any {minSelection} options',
  addOnMinAndMaxSelection: 'Required | Select any {minSelection} to {maxSelection} options',
  addOnMaxSelection: 'Optional | Select upto {maxSelection} option',
  addOnMaxSelectionMultiple: 'Optional | Select upto {maxSelection} options',
  maxLimitWarningAddon: 'You have reached the maximum limit for this item',
  minRequiredWarningAddon: 'You need to select at least {minSelection} item from {category}',
  bookingYourTable: 'Booking your Table',
  pleaseDoNotCloseApplication: 'Please do not close the application',
  alreadyTooManyBooking: 'Already Too Many Bookings',
  have5ActiveBookings:
    'You already have 5 active bookings. A guest cannot have more than 5 active bookings. Please book from a different account.',
  sameSlotBooking: 'Booking already exists',
  sameSlotBookingDescription:
    'Booking already exists for this time slot. Please try booking a different slot.',
  updateProfileTitle: 'Update Profile',
  updateProfileDescription: 'Please update your profile to continue',
  updateProfileCTA: 'UPDATE PROFILE',
  selectItem: 'Select',
  itemsAddedInCart: 'Items added in cart',
  customizationPopupAddItemCTA: 'ADD Item | {currency}{amount}',
  continueCTA: 'Continue',
  bogoItemPlaceholderText: '{stack} Item',
  bogoOfferPageTitle: 'Buy {buyQuantity} Get {getQuantity} Offer',
  bogoItemsTitle: 'Buy {buyQuantity} Get {getQuantity} Items',
  missedPointsOnOrder: "You could've {br} Earned Reward Points {br} on this order",
  earnedPointsOnOrder: "You've Earned Reward Points {br} on this order {br} Download app to Redeem",
  orderFromAppToEarnPoints: 'Order from app and earn points on every order',
  orderFromAppToRedeemPoints: 'Order from app to redeem your points',
  downLoadAppCTA: 'DOWNLOAD APP',
  downloadAppToManageProfileTitle: 'Download app to manage profile',
  downloadAppToManageProfileDescription:
    'You can manage your profile and do a lot more things on our app',
  swipeToBookTable: 'SWIPE TO BOOK TABLE',
  bogoOfferInstruction:
    'Collect reward, open menu on app, tap on ‘Offer Banner’, choose items of your choice. Coupon will be applied automatically.',
  bogoCouponTnC: 'Maximum of {getQuantity} item will be free on an order',
  bogoCouponPerTnC: 'Maximum of {getQuantity} item will be discounted on an order',
  bogoCouponMenuValidation: 'Valid on selected items',
  bogoCouponCTA: 'Select Items',
  bookingId: 'Booking ID - {bookingId}',
  offerExistInCart: 'Offer already added in cart',
  offerExistInCartDescription: 'Following items in your cart are part of the offer',
  removeItemsCTA: 'Remove Items',
  goToCartCTA: 'Go To Cart',
  applyCouponCTA: 'APPLY THIS COUPON',
  offerRemovePopupTitle: 'Applied coupon will be removed',
  offerRemovePopupDescription:
    'If you remove this item, your below applied coupon will also be removed.',
  offerRemovePopupCTA: 'REMOVE',
  offerRemovePopupSecondaryCTA: 'DO NOT REMOVE',
  offerNotApplicableTitle: '{preText} not applicable at the moment',
  offerNotApplicableDescription: 'Not applicable at the moment',
  offerNotApplicableTitle2: 'Offer not applicable at the moment',
  viewOfferDetails: 'View Offer Details',
  viewDetailsCTA: 'View Details',
  rateYourExperienceCTA: 'Rate Your Experience',
  yourRatingText: 'Your Rating',
  feedbackVisibleTimeInMinutes: '30',
  submitFeedbackCTA: 'SUBMIT FEEDBACK',
  orderFeedbackTitle: 'Order feedback',
  viewOrderText: 'view order',
  foodRatingQuestion: 'How was the food?',
  overallRatingQuestion: 'How was your experience?',
  serviceRatingQuestion: 'How was the service?',
  ambienceRatingQuestion: 'How was the ambience?',
  feedbackSuccessText:
    "Thanks a lot for showing us your love. We'll make sure that your next visit is awseome.",
  feedbackSuccessBadText:
    'Thank you for sharing your honest feedback.  We apologise for not meeting your expectations. Our team will get back to you to discuss it in detail.',
  weLoveYoutooText: 'We love you too',
  weAreImporvingText: 'We are improving',
  weAreSorryText: "We're so sorry",
  enjoyCouponText: 'Enjoy this coupon on your next visit',
  tellUsMore: 'Tell us more (optional)',
  lastOrderListSize: '1',
  lastOrderPreviousDays: '3',
  collectOrder: 'Collect your order from the counter',
  deliveryChargesTitle: 'Delivery Charges',
  deliveryDiscount: 'Delivery Discount',
  orderHistoryPopupTitle: 'Order History',
  orderHistoryPopupSubtitle: 'Download app to view your order history',
  orderHistoryPopupDesciption:
    'You can view your order history and do a lot more things on our app.',
  orderHistoryPopupCTA: 'Download APP',
  selfPickupLabel: 'Self Pickup',
  switchText: 'SWITCH',
  addMoreItemsTitle: 'Add more items',
  goToMenuCTA: 'GO TO MENU',
  minBillAmountTnc:
    'Minimum bill of {currencySymbol}{minBillValue} is required for delivery orders {br} Please add some more items in your cart',
  // paymentPollInterval: [0, 5, 10, 15, 25, 30, 5, 10, 15, 25, 30],
  paymentPollInterval: [0, 5, 10, 15, 20, 20, 20, 20, 20, 15, 5, 5],
  viewAllRatingsCTA: 'View all Ratings',
  featuredItemsTitle: 'Featured Items',
  swipeToPlaceOrder: 'Swipe To Place Order',
  rateYourLastExperience: 'Rate your last experience',
  swipeToPay: 'Swipe To Pay',
  nearby: 'Nearby',
  outletDistanceDisplayThresoldInKm: '1',
  nearbyOutletThresoldInMeter: '50',
  addToCartCTA: 'Add To Cart',
  availRewardWithPoints: 'Avail this reward with {pointPrice} reward points',
  rewardDetails: 'Reward Details',
  pointsDeducted: '{pointPrice} points will be deducted',
  appApplicableOnly: 'Applicable only on app orders',
  swipeToCollectReward: 'Swipe to Collect',
  excludeReservationStatus: 'PENDING',
  checkedInText: 'CHECKED IN',
  FoodImagePlaceholderUrl: 'https://assets.fudr.in/assets/images/food-image-placeholder.png',
  maximumDiscountText2: 'Upto ₹{maxValue}',
  knowMoreCTA: 'Know More',
  selectItemCTA: 'Select Items',
  applyOfferCTA: 'Apply Offer',
  offerText: 'Offer',
  rewardText: 'Reward',
  couponAlreadyTaken: 'You already have taken coupon with your {currencyName}',
  dayValidationError: '{preText} applicable only on {applicableWeekdays}',
  timeValidationError: '{preText} is available only from {startTime} to {endTime}',
  dateValidationError: '{preText} is available from {activationTime}',
  rewardValidationTitle: '{preText} not applicable at the moment',
  trackOrder: 'Track order',
  offerAlreadyAppliedTitle: '{preText} already applied',
  removeOfferCTA: 'Remove {preText}',
  amountValidationError:
    'This {preText} is applicable on minimum order amount of {currencySymbol}{minOrderValue}',
  amountValidationErrorTitle: 'Add More Items',
  expireValidationDateError: 'This {preText} is expired on {expireDate}',
  expireValidationError: 'This {preText} is expired',
  expireValidationErrorTitle: 'Offer Expired',
  useRewardPointToGetDiscount: 'Use {currencyName} to get discount on you bill',
  billAmountPaidWithPoints2: 'Pay upto {billAmount}% with {currencyName}',
  howToRedeemTitle: 'How to Redeem',
  applyPointsOnCart: 'Apply {currencyName} on cart page',
  okCTA: 'OK',
  itemNotAvailableTitle: 'Item not available',
  itemNotAvailableDescription:
    'This item is not available at the moment. {br} Sorry for the inconvenience',
  couponAppliedText: '{displayTitle} offer applied on this order',
  appliedCouponCTAText: 'YAY!',
  reviewOnSocialText:
    'Please review us on social channels and let the world know about your experience',
  reviewOnGoogeText: 'REVIEW US ON GOOGLE',
  reviewOnTripadvisorText: 'REVIEW US ON TRIPADVISOR',
  homeAddressTitle: 'Home',
  workAddressTitle: 'Work',
  otherAddressTitle: 'Other',
  nearbyOutletsSize: '5',
  viewMoreOutlets: 'View More',
  menuSearchPlaceholder: 'Search in Menu',
  menuSearchTitle: 'Search Menu',
  specialInstructions: 'Add Cooking Requests',
  swipeToOrderCTAText: 'SWIPE TO PLACE ORDER',
  swipeToPayCTAText: 'SWIPE TO PAY',
  emptyNameMessage: 'Please enter your name',
  emptyGenderMessage: 'Please select gender',
  emptyEmailMessage: 'Please enter your email',
  emptyDOBMessage: 'Please enter a date',
  emptyAnniversaryDateMessage: 'Please enter a date',
  namePlaceHolder: 'Your Name',
  emailPlaceHolder: 'Your email id',
  otpPlaceHolder: 'OTP',
  phoneNumberPlaceHolder: 'Mobile no.',
  doaPlaceHolder: 'dd-mm-yyyy',
  dobPlaceHolder: 'dd-mm-yyyy',
  signUpRequiredFields: ['name'],
  pointPrice: '{pointPrice} Points',
  lockedItemDealCTA: 'View Items',
  unlockedItemDealCTA: 'Select Items',
  promoCodePlaceholder: 'Enter promo code',
  applyPromoCodeCTA: 'APPLY',
  promoCodeNotFoundDescription: 'The code you have entered is incorrect or expired.',
  promoCodeNotFoundTitle: 'Promo code not found',
  promoOfferTitle: 'Promo Offer',
  tncTitle: 'Terms & Conditions',
  promoCodeTitle: 'Have a Promo Code?',
  loyaltyPassTitle: 'Loyalty Pass',
  applyCTAText: 'Apply',
  unlockYourReward: '{name}, You’re just one step away from unlocking {displayTitle}',
  unlockYourRewardDescrption: 'Register Now to claim this offer',
  verifyAndUnlockOffer: 'Unlock Offer',
  grabOfferTitle: 'You are one step away from claiming the offer. Grab it now !',
  demoOfferTnC: 'This offer is a demo, provided exclusively for visualization purposes',
  otpVerificationTitle: 'OTP Verification',
  orderConfirmationDemoTitle: 'Thank you for completing the demo!',
  orderConfirmationDemoDescription: 'We hope you found it engaging.',
  orderNowCTA: 'Order Now',
  rewardProgramSubtext: ' ',
  dealListOrderPriority: ['loyalty', 'deal', 'promo'],
  enterReferralCode: 'Got a referral Code? {cta}',
  referralCodeFieldLabel: 'Referral Code',
  referralCodePlaceHolder: 'Enter Referral Code',
  enterReferralCodeCTA: 'Enter Code',
  invalidReferralCodeTitle: 'Invalid Referral Code',
  invalidReferralCodeDescription:
    'The referral code you’ve entered is not valid. Please enter a valid code',
  referrals: 'My Referrals',
  referralTabTitle: 'Refer & Earn',
  referralCardTitle: 'Refer and Earn',
  referralPopupTitle: 'Refer & Earn',
  howReferralWorksTitle: 'How it works',
  referralCodeTnc: 'Share your Referral code with your friends',
  referralsTitle: 'Referrals',
  referralPointCardRule:
    'Share your Referral code with your friends and {rewardPoints} points when they place their first order',
  referralCouponCardRule:
    'Share your Referral code with your friends and get {dealName} when they place their first order',
  referralPointRule:
    'You’ll receive ‘{rewardPoints}’ points when your friend places their first order',
  referralCouponRule:
    'You’ll receive ‘{dealName}’ reward when your friend places their first order',
  gainerPointRule: 'They’ll receive ‘{rewardPoints}’ points on signing up with your referral link',
  gainerCouponRule: 'They’ll receive ‘{dealName}’ reward on signing up with your referral link',
  referralMilestonePointRule: 'Get {rewardPoints} points on {thresold} successful referrals',
  referralMilestoneCouponRule: 'Get {dealName} on {thresold} successful referrals',
  referralMilestonePointMaxThresoldRule:
    'Get {rewardPoints} points on {thresold} successful referrals upto {maxThresold} referrals',
  referralMilestoneCouponMaxThresoldRule:
    'Get {dealName} on {thresold} successful referrals upto {maxThresold} referrals',
  referralRewardKnowMoreCTA: 'Know More',
  referralCodewhatsappShareMessage:
    'Hey, I’m using {brandName} app. Use my referral code {referralCode}',
  signupDetailsTitle: 'One Last Thing',
  unlockCouponTitle: 'You’ve unlocked {br} {displayTitle} coupon',
  unlockFallbackCouponTitle:
    '{displayTitle} coupon is for new guests, we’re happy to treat you to a {fallbackDisplayTitle} coupon!',
  unlockCouponDescription: 'We’ve DMed you this coupon. {br} Redeem it on your next order',
  couponCodeText: 'Coupon Code - {couponCode}',
  noOfferApplicableTitle: 'Sorry, this offer is not applicable',
  noOfferApplicableDescription:
    'However, we’ve got you covered with exciting rewards from our loyalty program on our App!',
  goBackCTA: 'Go Back',
  changeCTA: 'Change',
  referralMilestoneProgress: '{completed}/{total} Referrals completed',
  genderFields: ['MALE', 'FEMALE', 'OTHER'],
  unlockFallbackOfferTitle:
    '{displayTitle} is only for newly joined members. However, we have got you covered with a special treat!',
  unlockOfferTitle: 'You’ve unlocked {br} {displayTitle}',
  getBrandAppCTA: 'Go to {brandName} App',
  offerAlreadyRedeemed: 'Offer Successfully Redeemed',
  unlockMoreOfferOnApp: 'Unlock more offers and earn rewards {br} Get the app now!',
  getMoreOffers: 'Get More Offers!',
  offerExpiredTitle: 'Sorry, this offer has expired.',
  getDealTitleCTA: 'Get {dealTitle}',
  rewardPointEarnedTextWithoutCurrency: `{rewardPointsText} {br} Earned on this order`,
  cartAdditionalSupportText:
    'Prices include container charges (not applicable to beverages & add-ons)',
  orderingAtTitle: 'Ordering at',
};
export default resourcesConfig;
